import api  from "./api";
export function changeToLocalString(value, locales, amountObj) {
    let response = parseFloat(parseFloat(value).toFixed(2)).toLocaleString(locales, amountObj);
    return response;
}

export function getAllTaxes(api, setTaxProfiles, taxType, callback) {
    let taxData = JSON.parse(localStorage.getItem('taxData'));
    if (taxData && Array.isArray(taxData)){
        console.log("tax data exists");
        if (typeof callback === "function" && typeof setTaxProfiles === "function") {
            populateTaxData(setTaxProfiles, taxType, false, callback)
        }
        return Promise.resolve("tax data exists");
    }
    else
    {
        const response = api.get('tax-profiles');
        response.then((res)=>{
            let taxData = JSON.stringify(res);
            //removing it on page load, because if a tax value is changed it should reflect in sale page
            localStorage.removeItem('taxData')
            localStorage.setItem('taxData',taxData);
            if (typeof callback === "function" && typeof setTaxProfiles === "function") {
                populateTaxData(setTaxProfiles, taxType, false, callback)
            }
        }).catch((err)=>{
           console.log(err);
        })
        return response;
    }
}

export function updateLocalStorageTaxData(data) {
        localStorage.removeItem('taxData')
        localStorage.setItem('taxData',JSON.stringify(data))
}

export function populateTaxData(setTaxProfiles, taxType, uniqueFlag, callback) {
    /* 
        the param taxType can be an array or a single value, sometimes there are use cases where we have to load
        two or more different tax profiles in the taxProfile dropdown

        Note: added a new param uniqueFlag, if set True only unique tax profiles will be set (for customer and vendor tax report)
    */
        let obj = [];
        let taxData = JSON.parse(localStorage.getItem('taxData'));
        if (taxData !== undefined && taxData!==null && Array.isArray(taxData) && taxData.length > 0) {
                if (Array.isArray(taxType) && taxType.length > 0) {
                        for (let outerindex = 0; outerindex < taxData.length; outerindex++) {
                            for (let innerindex = 0; innerindex < taxType.length; innerindex++) {
                                //filters out unique obj (for customer and vendor tax report)
                                    if (uniqueFlag && taxData[outerindex].taxType === taxType[innerindex]) {
                                        if (obj.length > 0) {
                                            var tax = obj.filter((el)=>el.taxType === taxType[innerindex])
                                            if (tax.length === 0) {
                                                obj.push(taxData[outerindex])
                                            }
                                        }
                                        else {
                                            obj.push(taxData[outerindex])
                                        }
                                    }
                                
                                else
                                {
                                    if (taxData[outerindex].taxType === taxType[innerindex]) {
                                        obj.push(taxData[outerindex])
                                    }
                                }
                            }                        
                    }   
                }
                else
                {
                    obj = taxData.filter((el)=>el.taxType === taxType);
                }
                if(Array.isArray(obj) && obj.length > 0){
                        setTaxProfiles(obj)
                        if (typeof callback === "function") {
                            callback(obj)
                        }
                }
                else
                {
                    console.log('tax data not found');
                }
                
            }
            else
            {
                console.log('tax data not found');
            }
}

export function setTaxValueFromTaxProfile(event, TaxProfiles, setFormState, formState, callback) {
    const idx = event.target.value;

    if (TaxProfiles[idx].taxType==="GST") {
        setFormState({
            ...formState,
            values:{
                ...formState.values,
                tax:TaxProfiles[idx].taxPercentage
            },
            touched: {
                tax: true
            }
        })
        if (TaxProfiles[idx].taxType==="GST" && callback && typeof callback === "function") {
            callback(TaxProfiles[idx].taxPercentage)
        }
    }
    else if (TaxProfiles[idx].taxType==="TDS") {
        setFormState({
            ...formState,
            values:{
                ...formState.values,
                tds:TaxProfiles[idx].taxPercentage
            },
            touched: {
                tds: true
            }
        })
        if (TaxProfiles[idx].taxType==="TDS" && callback && typeof callback === "function") {
            callback(TaxProfiles[idx].taxPercentage)
        }
    }
    else {
        setFormState({
            ...formState,
            values:{
                ...formState.values,
                tcs:TaxProfiles[idx].taxPercentage
            },
            touched: {
                tcs: true
            }
        })
        if (TaxProfiles[idx].taxType==="TCS" && callback && typeof callback === "function") {
            callback(TaxProfiles[idx].taxPercentage)
        }
    }
            
}


export async function getCurrentTimeByAccount() {
    const response = api.get('/GetCurrentTimeByAccount');
    return response;
}

export async function generatePdfFile(payload, endpoint, fileName) {
    try {
        const response = await api.post(endpoint, payload, {
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.pdf`); 
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
    } catch (error) {
        console.error(error);
    }
}